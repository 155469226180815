<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <MyTable
            :columns="columns"
            :rows="rows"
            :isLoading="isLoading"
            :customButton="customButton"
            @customButton="handleLP"
            :hasExport="true"
            @handleExport="handleExport"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'OutletID'">
                <b-button variant="gradient-primary" block>
                  {{ props.row.OutletID }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'orderID'">
                <b-button variant="gradient-primary" block>
                  {{ props.row.orderID }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'transactionID'">
                <b-button variant="gradient-primary" block>
                  {{ props.row.transactionID }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'balance'">
                {{ !props.row.balance ? "" : props.row.balance }}
              </div>
              <div v-if="props.column.field === 'date'">
                {{
                  !props.row.date
                    ? ""
                    : props.row.date.split("T")[0] +
                      " " +
                      props.row.date.split("T")[1].substr(0, 5)
                }}
              </div>
              <span v-if="props.column.field === 'shipmentType'">
                <b-button
                  v-if="props.row.shipmentType === 'Outlet'"
                  block
                  variant="yellow"
                >
                  Outlet
                </b-button>
                <b-button
                  v-else-if="props.row.shipmentType === 'SINGAPORE'"
                  block
                  variant="purple"
                >
                  SINGAPORE
                </b-button>
                <b-button
                  v-else-if="props.row.shipmentType === 'B2BPRO'"
                  block
                  variant="light-blue"
                >
                  B2BPRO
                </b-button>
                <b-button
                  v-else-if="props.row.shipmentType === 'B2C'"
                  block
                  variant="dark-purple"
                >
                  B2C
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.shipmentType }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'remarks'">
                {{ !props.row.remarks ? "" : props.row.remarks }}
              </div>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <ValidationObserver ref="logpoint">
      <b-modal
        size="lg"
        ref="handleLP"
        centered
        title="ADD / REDUCE LP"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Username" class="w-100">
                <b-row>
                  <b-col cols="6" class="pr-0 d-flex align-items-center">
                    <div class="d-flex">
                      <b-form-radio
                        v-model="userType"
                        name="User"
                        value="User"
                        class="mr-2"
                      >
                        User
                      </b-form-radio>
                      <b-form-radio
                        v-model="userType"
                        name="Outlet"
                        value="Outlet"
                      >
                        Outlet
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="Username"
                        name="Username"
                        rules="required"
                      >
                        <v-select
                          v-model="userName"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userOptions"
                          :clearable="false"
                          label="name"
                          required
                          placeholder="Username"
                          class="font-small-3 w-100"
                        >
                          <template #option="option">
                            <span>{{ option.name }}</span>
                          </template>
                        </v-select>
                        <span>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </span>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col cols="6" class="pr-0 d-flex align-items-center">
                    <div class="d-flex">
                      <b-form-radio
                        v-model="mode"
                        name="Add"
                        value="Add"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio v-model="mode" name="Reduce" value="Reduce">
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="LP Amount"
                        rules="required"
                      >
                        <input
                          v-model="amount"
                          type="text"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Type of Shipment" class="w-100">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Shipment Type"
                  rules="required"
                >
                  <v-select
                    v-model="shipmentType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="shipmentOptions"
                    :clearable="false"
                    label="shipmentType"
                    required
                    placeholder="Shipment Type"
                    class="font-small-3 w-100"
                  >
                    <template #option="option">
                      <span>{{ option.shipmentType }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                  class="w-100"
                >
                  <input
                    v-model="remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button
              @click.prevent="addOrReduceLP()"
              variant="gradient-primary"
              type="submit"
            >
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import exportFromJSON from "export-from-json";
import { required } from "@validations";

export default {
  components: {
    flatPickr,
    MyTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  async created() {
    this.isLoading = false;
  },

  methods: {
    handleLP() {
      this.$refs.handleLP.show();
    },

    addOrReduceLP() {
      this.$refs.logpoint.validate().then((success) => {
        if (success) {
          console.log("Add Reduce LP");
          this.$refs.handleLP.hide();
        }
      });
    },

    handleExport(type) {
      const data = this.rows;
      const fileName = "generated-logpoints";
      let exportType;
      switch (type) {
        case "xlsx":
          exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case "csv":
          exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          break;
      }
    },
  },

  data() {
    return {
      isLoading: true,
      customButton: {
        label: "Add / Reduce LP",
        icon: "PlusCircleIcon",
      },
      userType: "User",
      userName: null,
      userOptions: [
        {
          name: "User1",
        },
        {
          name: "User2",
        },
      ],
      mode: "Add",
      amount: null,
      shipmentType: null,
      shipmentOptions: [
        {
          shipmentType: "Outlet",
        },
        {
          shipmentType: "SINGAPORE",
        },
        {
          shipmentType: "B2BPRO",
        },
        {
          shipmentType: "B2C",
        },
      ],
      remarks: "",
      columns: [
        {
          label: "Outlet ID",
          field: "OutletID",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order ID",
          field: "orderID",
          tdClass: "text-center align-middle",
        },
        {
          label: "Transaction ID",
          field: "transactionID",
          tdClass: "text-center align-middle",
        },
        {
          label: "LP Balance",
          field: "balance",
          tdClass: "text-center align-middle",
        },
        {
          label: "Deduction Date & Time",
          field: "date",
          tdClass: "text-center align-middle",
        },
        {
          label: "Type of Shipment",
          field: "shipmentType",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [
        {
          OutletID: 123,
          orderID: 12,
          transactionID: 415,
          balance: 1000.0,
          date: new Date(),
          shipmentType: "Outlet",
          remarks: "Lorem ipsum dolores",
        },
        {
          OutletID: 123,
          orderID: 12,
          transactionID: 415,
          balance: 1000.0,
          date: new Date(),
          shipmentType: "SINGAPORE",
          remarks: "Lorem ipsum dolores",
        },
        {
          OutletID: 123,
          orderID: 12,
          transactionID: 415,
          balance: 1000.0,
          date: new Date(),
          shipmentType: "B2BPRO",
          remarks: "Lorem ipsum dolores",
        },
        {
          OutletID: 123,
          orderID: 12,
          transactionID: 415,
          balance: 1000.0,
          date: new Date(),
          shipmentType: "B2C",
          remarks: "Lorem ipsum dolores",
        },
      ],
      required,
    };
  },
};
</script>
